const profileErrorMessages = {};
profileErrorMessages["1440001"] =
  "This password has already been used, please choose a different password.";
// ErrorCode - 1440002 - unlikely scenario but safer side we are handling here.
profileErrorMessages["1440002"] =
  "You must choose a password with a minimum of 8 characters, including an upper case, lower case, number and special character.";

profileErrorMessages["1440301"] =
  "The current password you have entered is invalid. Please try again.";

profileErrorMessages["1450001"] =
  "An unexpected error has occurred. Please try again later.";

profileErrorMessages["1499901"] =
  "Something went wrong. Please try again later.";

export default profileErrorMessages;
