import { Link } from "react-router-dom";
import useAuth from "../../hooks/use-auth/useAuth";

const HeaderLogo = () => {
  const { auth } = useAuth();

  return (
    <div className="d-flex">
      <Link
        to={auth?.user ? "/home" : "/"}
        className="py-1 px-4 px-sm-0 navbar-brand"
        aria-label="Go to home page"
      >
        <div className="brand-logo"></div>
      </Link>
    </div>
  );
};

export default HeaderLogo;
