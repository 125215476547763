import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { trimStartOnChange } from "../../../utils/form/form-text-utils";

const PhoneNumbersFormFields = () => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
    watch,
    trigger,
  } = methods;

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "mobileNumber":
          trigger(["homeNumber", "workNumber"], { shouldFocus: false });
          break;
        case "homeNumber":
          trigger(["mobileNumber", "workNumber"], { shouldFocus: false });
          break;
        case "workNumber":
          trigger(["mobileNumber", "homeNumber"], { shouldFocus: false });
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  return (
    <>
      <Form.Group className="mb-3" controlId="mobileNumber">
        <Form.Label>Mobile number</Form.Label>
        <Form.Control
          type="string"
          maxLength={20}
          isInvalid={errors.mobileNumber}
          {...register("mobileNumber", {
            onChange: trimStartOnChange,
          })}
        />
        {errors.mobileNumber?.message &&
          /[[MINLENGTH]]/.test(errors.mobileNumber.message) && (
            <Form.Control.Feedback type="invalid">
              {errors.mobileNumber?.message.replace("[[MINLENGTH]]", "")}
            </Form.Control.Feedback>
          )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="workNumber">
        <Form.Label>Work number</Form.Label>
        <Form.Control
          type="string"
          maxLength={20}
          isInvalid={errors.workNumber}
          {...register("workNumber", {
            onChange: trimStartOnChange,
          })}
        />
        {errors.workNumber?.message &&
          /[[MINLENGTH]]/.test(errors.workNumber.message) && (
            <Form.Control.Feedback type="invalid">
              {errors.workNumber?.message.replace("[[MINLENGTH]]", "")}
            </Form.Control.Feedback>
          )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="homeNumber">
        <Form.Label>Home number</Form.Label>
        <Form.Control
          type="string"
          maxLength={20}
          isInvalid={errors.homeNumber}
          {...register("homeNumber", {
            onChange: trimStartOnChange,
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.homeNumber?.message.replace("[[MINLENGTH]]", "")}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default PhoneNumbersFormFields;
