const usersErrorMessages = {};
usersErrorMessages[`1240100`] =
  "An unexpected error has occurred. Please try again later.";
usersErrorMessages[`1240101`] =
  "We have not been able to validate your details or an account already exist please check and try again. If you already have an account and have forgotten your password please follow the forgotten your details link.";
usersErrorMessages[`1240102`] =
  "Sorry, we can't identify you from the information you've given. Please check all fields are correct and submit the form again. If you're still having problems with the form after checking your details are correct, please call Customer Service on 0344 879 6633. Our phone lines are open Monday - Friday between 9am - 5pm. We're closed on weekends and bank holidays.";
usersErrorMessages[`1240103`] =
  "Sorry, we can't identify you from the information you've given. Please check all fields are correct and submit the form again. If you're still having problems with the form after checking your details are correct, please call Customer Service on 0344 879 6633. Our phone lines are open Monday - Friday between 9am - 5pm. We're closed on weekends and bank holidays.";
usersErrorMessages[`1240104`] =
  "Sorry, we can't identify you from the information you've given. Please check all fields are correct and submit the form again. If you're still having problems with the form after checking your details are correct, please call Customer Service on 0344 879 6633. Our phone lines are open Monday - Friday between 9am - 5pm. We're closed on weekends and bank holidays.";
usersErrorMessages[`1240105`] =
  "Sorry, we can't identify you from the information you've given. Please check all fields are correct and submit the form again. If you're still having problems with the form after checking your details are correct, please call Customer Service on 0344 879 6633. Our phone lines are open Monday - Friday between 9am - 5pm. We're closed on weekends and bank holidays.";
usersErrorMessages[`1240106`] =
  "This website is not available for the type of account you hold. Please contact Customer Service to discuss the options available to you.";
usersErrorMessages[`1240107`] =
  "You already have an account. If you have forgotten your login details please follow the forgotten your details link.";
usersErrorMessages[`1240108`] =
  "You have entered invalid user ID or password or your account is locked, please try again or click on forgotten your details link to reset your account.";
usersErrorMessages[`1240109`] =
  "The temporary password that you have entered has now expired please request a new password. Please click on forgotten your details link.";
usersErrorMessages[`1240110`] =
  "Your details have not been found, someone from our online support team will be in touch.";
usersErrorMessages[`1240111`] =
  "The user alias you have requested is already in use, please choose another user alias otherwise you will be unable to access your new account or agreement.";
usersErrorMessages[`1240112`] = "Account exists but locked.";

usersErrorMessages[`1250000`] = "Something went wrong. Please try again later.";

usersErrorMessages[`1240300`] =
  "You have entered invalid user ID or password or your account is locked, please try again or click on forgotten your details link to reset your account.";
usersErrorMessages[`1240301`] =
  "Your account has been locked as we have not been able to validate your identity. Someone from our online support team will be in touch.";

export default usersErrorMessages;
