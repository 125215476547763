import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  VehicleRegOrFleetNumberField,
  UserIdField,
  FirstNameField,
  LastNameField,
  DobField,
  IsExistingUserField,
  AcceptRegisterTerms,
} from "../fields";
import * as fieldSchemas from "../schemas/fields";

const RegisterForm = ({ onFormSubmit }) => {
  const methods = useForm({
    defaultValues: { isExistingUser: "no" },
    resolver: yupResolver(
      yup.object().shape(
        {
          ...fieldSchemas.isExistingUserSchema,
          userId: yup
            .string()
            .when(["isExistingUser"], ([isExistingUser], schema) => {
              return isExistingUser === "yes"
                ? fieldSchemas.userIdSchema.userId
                : schema.notRequired();
            }),
          fleetNumber: yup
            .string()
            .when(["isExistingUser"], ([isExistingUser], schema) => {
              return isExistingUser === "no"
                ? fieldSchemas.vehicleRegOrFleetSchema.fleetNumber
                : schema.notRequired();
            }),
          vehicleReg: yup
            .string()
            .when(["isExistingUser"], ([isExistingUser], schema) => {
              return isExistingUser === "no"
                ? fieldSchemas.vehicleRegOrFleetSchema.vehicleReg
                : schema.notRequired();
            }),
          firstName: yup
            .string()
            .when(["isExistingUser"], ([isExistingUser], schema) => {
              return isExistingUser === "no"
                ? fieldSchemas.firstNameSchema.firstName
                : schema.notRequired();
            }),
          lastName: yup
            .string()
            .when(["isExistingUser"], ([isExistingUser], schema) => {
              return isExistingUser === "no"
                ? fieldSchemas.lastNameSchema.lastName
                : schema.notRequired();
            }),
          dobDay: yup
            .string()
            .when(["isExistingUser"], ([isExistingUser], schema) => {
              return isExistingUser === "no"
                ? fieldSchemas.dobSchema.dobDay
                : schema.notRequired();
            }),
          dobMonth: yup
            .string()
            .when(["isExistingUser"], ([isExistingUser], schema) => {
              return isExistingUser === "no"
                ? fieldSchemas.dobSchema.dobMonth
                : schema.notRequired();
            }),
          dobYear: yup
            .string()
            .when(["isExistingUser"], ([isExistingUser], schema) => {
              return isExistingUser === "no"
                ? fieldSchemas.dobSchema.dobYear
                : schema.notRequired();
            }),
          dateOfBirth: yup
            .string()
            .when(["isExistingUser"], ([isExistingUser], schema) => {
              return isExistingUser === "no"
                ? fieldSchemas.dobSchema.dateOfBirth
                : schema.notRequired();
            }),
          ...fieldSchemas.areRegisterTermsAcceptedSchema,
        },
        [...fieldSchemas.vehicleRegOrFleetSchemaDependencies]
      )
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit, watch } = methods;

  const watchIsExistingUser = watch("isExistingUser");

  const onSubmit = (data) => {
    onFormSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate name="RegisterForm">
        <IsExistingUserField />
        <hr />
        <div
          className={`${watchIsExistingUser === "no" ? "d-block" : "d-none"}`}
        >
          <VehicleRegOrFleetNumberField />
          <FirstNameField />
          <LastNameField />
          <DobField />
        </div>
        <div
          className={`${watchIsExistingUser === "yes" ? "d-block" : "d-none"}`}
        >
          <UserIdField labelText="User ID" />
        </div>
        <AcceptRegisterTerms />

        <br />
        <div className="d-grid gap-2">
          <Button className="px-5" type="submit" id="btnRegisterFormSubmit">
            Register
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

RegisterForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default RegisterForm;
