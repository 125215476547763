import * as yup from "yup";
import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useChangePassword } from "../../../api/hooks/auth";
import * as fieldSchemas from "../schemas/fields/password-schema";
import {
  CurrentPasswordField,
  PasswordAndConfirmPasswordFields,
} from "../fields/password-fields";

const ChangePasswordForm = ({ scrollTop }) => {
  const [successMessage, showSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const schema = yup.object().shape({
    ...fieldSchemas.currentPasswordSchema,
    ...fieldSchemas.newPasswordSchema,
    ...fieldSchemas.confirmNewPasswordSchema,
  });
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const { handleSubmit, reset } = methods;

  const onUpdateSuccessful = () => {
    reset();
    showSuccessMessage(true);
    scrollTop();
  };
  const onUpdateFailure = (error) => {
    setErrorMessage(error?.localErrorMessage);
    scrollTop();
  };

  const { mutate } = useChangePassword(onUpdateSuccessful, onUpdateFailure);

  const onSubmit = (data) => {
    setErrorMessage("");
    showSuccessMessage(false);
    let payload = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    };
    mutate(payload);
  };

  return (
    <>
      {successMessage && (
        <Alert
          variant="success"
          onClose={() => showSuccessMessage(false)}
          dismissible
        >
          Thank you. Your details have now been successfully updated.
        </Alert>
      )}
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
          {errorMessage}
        </Alert>
      )}
      <h2 className="text-primary mb-4">Password</h2>
      <p>
        To change your password you will need to enter your existing password
        first.
      </p>

      <Row>
        <Col lg={6} md={8} sm={12}>
          <FormProvider {...methods}>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className="mt-4"
              name="changePasswordForm"
            >
              <CurrentPasswordField />
              <PasswordAndConfirmPasswordFields
                passwordControlId="newPassword"
                passwordLabelText="New password"
                passwordConfirmControlId="confirmNewPassword"
                passwordConfirmLabelText="Confirm new password"
              />
              <div className="d-grid gap-2">
                <Button
                  className="px-5 mt-5"
                  type="submit"
                  id="btnUpdatePassword"
                >
                  Update
                </Button>
              </div>
            </Form>
          </FormProvider>
        </Col>
      </Row>
    </>
  );
};

export default ChangePasswordForm;
