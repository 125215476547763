import TilesBasePage from "../basepages/tiles-basepage/TilesBasePage";
import { Row } from "react-bootstrap";
import Tile from "../../component/tile/Tile";
import tileDetails from "./tiles-config.json";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";

import {
  getSavedBrand,
  saveBrand,
  isSupportedBrand,
  getDefaultBrand,
} from "../../utils/branding/brand-utils";
import useAuth from "../../hooks/use-auth/useAuth";
import HomeBanner from "../../component/notification-banner/home-banner/HomeBanner";
import {
  useGetAccountBalance,
  useGetAccountNotifications,
} from "../../api/hooks/account";

const HomePage = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const lastLogon = auth?.user?.lastLogon
    ? DateTime.fromISO(auth.user.lastLogon).toFormat("yyyy-MM-dd")
    : DateTime.now().minus({ years: 5 }).toFormat("yyyy-MM-dd");

  useEffect(() => {
    const savedBrand = getSavedBrand();
    let userBrandingCode = auth?.user?.brandingCode;
    if (isSupportedBrand(userBrandingCode)) {
      userBrandingCode = userBrandingCode.toLowerCase().trim();
    } else {
      userBrandingCode = getDefaultBrand();
    }
    if (savedBrand !== userBrandingCode) {
      if (saveBrand(userBrandingCode)) {
        if (typeof window?.location?.reload === "function") {
          window.location.href = "/";
        }
      }
    }
  }, [auth]);

  const { data: balanceDetails, isError } = useGetAccountBalance();
  const { data: notificationsDetails, isError: isNotificationsError } =
    useGetAccountNotifications(lastLogon);

  const onPayClicked = () => {
    navigate("/payments/make-a-payment");
  };

  const getTileCounter = (tile) => {
    let counter = 0;
    if (!isNotificationsError) {
      if (tile.cardId === "order") {
        counter = notificationsDetails?.orders ?? 0;
      } else if (tile.cardId === "documents") {
        counter = notificationsDetails?.documents ?? 0;
      } else if (tile.cardId === "transaction") {
        counter = notificationsDetails?.transactions ?? 0;
      } else if (tile.cardId === "invoice") {
        counter = notificationsDetails?.invoices ?? 0;
      }
    }
    return counter;
  };

  return (
    <TilesBasePage
      pageTitle="Manage My Vehicle"
      pageSubTitle="Find invoices, ask us to collect a vehicle, update your personal details and more."
    >
      {!isError &&
        balanceDetails &&
        (balanceDetails.paymentMethod || balanceDetails.balanceStatus) &&
        !(
          balanceDetails.balanceStatus === "NotDue" ||
          balanceDetails.paymentMethod === "SpecialDirectDebit" ||
          balanceDetails.paymentMethod === "Litigation"
        ) && (
          <>
            <HomeBanner
              balanceDetails={balanceDetails}
              onPayClicked={onPayClicked}
            />
          </>
        )}
      <Row className="mt-3">
        {tileDetails.map((tile) => {
          return (
            <Tile
              key={tile.cardTitle}
              cardId={tile.cardId}
              cardTitle={tile.cardTitle}
              cardLinkUrl={tile.cardLinkUrl}
              cardLinkContent={tile.cardLinkContent}
              isLink={true}
              counter={getTileCounter(tile)}
            >
              {tile.cardText}
            </Tile>
          );
        })}
      </Row>
    </TilesBasePage>
  );
};

export default HomePage;
