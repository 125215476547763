import * as yup from "yup";

const phoneNumberMinLength = (numberType, minLength) => {
  return {
    name: "minPhoneLength",
    exclusive: true,
    params: { numberType, minLength },
    message:
      // Using [[MINLENGTH]] placeholder as an indicator that this is minlengh error.
      // Do not remove the [[MINLENGTH]] placeholder as it is removed when displaying message.
      `[[MINLENGTH]]Please enter a valid ${numberType}, minimum ${minLength} digits.`,
    test: (value) =>
      value == null || value.length === 0 || value.length >= minLength,
  };
};

const phoneNumbersSchema = {
  mobileNumber: yup
    .string()
    .when(["workNumber", "homeNumber"], ([workNumber, homeNumber], schema) => {
      return !workNumber && !homeNumber
        ? schema.required().test(phoneNumberMinLength("mobile number", 11))
        : schema.notRequired().test(phoneNumberMinLength("mobile number", 11));
    }),
  workNumber: yup
    .string()
    .when(
      ["mobileNumber", "homeNumber"],
      ([mobileNumber, homeNumber], schema) => {
        return !mobileNumber && !homeNumber
          ? schema.required().test(phoneNumberMinLength("work number", 11))
          : schema.notRequired().test(phoneNumberMinLength("work number", 11));
      }
    ),
  homeNumber: yup
    .string()
    .when(
      ["mobileNumber", "workNumber"],
      ([mobileNumber, workNumber], schema) => {
        return !mobileNumber && !workNumber
          ? schema
              .required(
                "Please enter at least one valid mobile number, work number or home number."
              )
              .test(phoneNumberMinLength("home number", 11))
          : schema.notRequired().test(phoneNumberMinLength("home number", 11));
      }
    ),
};

const phoneNumbersSchemaDependencies = [
  ["mobileNumber", "homeNumber"],
  ["mobileNumber", "workNumber"],
  ["homeNumber", "workNumber"],
];

export default phoneNumbersSchema;

export { phoneNumbersSchemaDependencies };
