import { Row, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import BasePage from "../../basepages/basepage/BasePage";
import { setItem, removeItem } from "../../../storage/SessionStorage";
import { useSetUserCredentials } from "../../../api/hooks/user-credentials";
import useRegistrationStepCheck from "../hooks/useRegistrationStepCheck";
import AuthContext from "../../../providers/auth-provider/AuthProvider";
import UserCredentialForm from "../../../component/forms/register/UserCredentialForm";

const UserCredentialPage = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const { setAuth } = useContext(AuthContext);

  useRegistrationStepCheck("PENDINGPASSWORD");

  const setUserDetails = (data) => {
    setItem("user", data?.user);
    setAuth({ user: data?.user, accessToken: data?.accessToken });
  };

  const onUpdateSuccessful = (data) => {
    let nextPage = "/";
    const status = data?.user?.status.toUpperCase();
    if (status === "PENDINGCHECK") {
      nextPage = "/iiq";
      setUserDetails(data);
    } else if (status === "PENDINGIDENTITYCHECK") {
      nextPage = "/verification";
      setUserDetails(data);
    } else if (status === "OK") {
      nextPage = "/confirmation";
      setAuth({});
    }
    removeItem("temp_email");
    navigate(nextPage, { replace: true });
  };

  const onUpdateFailure = (error) => {
    setErrorMessage(error?.localErrorMessage);
    window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
  };

  const { mutate } = useSetUserCredentials(onUpdateSuccessful, onUpdateFailure);

  const onSubmit = (data) => {
    setErrorMessage("");
    const payload = {
      email: data.emailAddress,
      password: data.currentPassword,
      useralias: data.alias,
    };
    mutate(payload);
  };

  return (
    <BasePage
      pageTitle="Manage My Vehicle"
      pageSubTitle="To create your account complete the below information."
    >
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
          {errorMessage}
        </Alert>
      )}
      <h2 className="h4 mt-4 h3">
        Please create a new password below, and then retype it in the 'Confirm
        password' box ensuring the two entries match.
      </h2>
      <p>
        {
          "Your password must be a minimum of 8 characters long, include at least one upper and lower case letter, one number and one of the following permitted special characters ->$@#%^&* "
        }
      </p>
      <hr className="my-4" />
      <Row>
        <Col lg={6} md={8} sm={12}>
          <UserCredentialForm onFormSubmit={onSubmit} />
        </Col>
      </Row>
      <br />
    </BasePage>
  );
};

export default UserCredentialPage;
